'use client'
import React, { useState, useEffect, useRef } from 'react'
// import { useSession } from 'next-auth/react'
import Image from 'next/image'
import LinkComponent from './LinkComponent'
import { AiOutlineLayout, AiFillHeart, AiOutlineHeart } from "react-icons/ai"
import { BiArea, BiSolidArea } from "react-icons/bi";
import { FaBed, FaBath } from "react-icons/fa"
import { FaMountain } from "react-icons/fa";
import { MdBeachAccess, MdPlaylistAddCheckCircle } from "react-icons/md";
import { FaWater } from "react-icons/fa";
import { IoMdVideocam } from 'react-icons/io'
import tailwindConfig from '@tailwind.config'
import CurrencySymbol from '@components/CurrencySymbol'

const NewDevelopmentCard = ({ project, imageWidth = 400, imageHeight = 300, dictionary, lang }) => {
    // const [showLikeBtn, setShowLikeBtn] = useState(false)
    // const [isLiked, setIsLiked] = useState(property.isLiked)
    // const { data: session } = useSession()

    // const handleLike = async () => {
    //     const isLike = await sendLike(property.id, session?.user?.email)
    //     setIsLiked((prevLike) => !prevLike)
    //     property.isLiked = !property.isLiked
    // };


    // useEffect(() => {
    //     if (session && session.user !== null) {
    //         setShowLikeBtn(true)
    //     }
    // }, [])

    return (
        <div className='relative w-full m-1 rounded-lg shadow-md hover:shadow-sm hover:shadow-maincolor'
        >
            <LinkComponent
                className='w-full'
                href={'/new-developments/' + project.slug}
            >
                <div className='relative w-full aspect-[4/3] rounded-t-md overflow-hidden'>
                    {/* {(showLikeBtn == true && isLiked == false) && (
                        <div title="Left-click on this icon to save this property" onClick={handleLike} className='absolute flex flex-row flex-wrap top-1 right-1 z-40 h-8 w-8 justify-center items-center border-[1px] border-secondary rounded-md p-1'>
                            <AiOutlineHeart
                                size={24}
                                color={tailwindConfig.theme.extend.colors.secondary}
                            />
                        </div>
                    )} */}
                    {/* {(showLikeBtn == true && isLiked == true) && (
                        <div title="Left-click to unlike this property" onClick={handleLike} className='absolute flex flex-row flex-wrap top-1 right-1 z-40 h-8 w-8 justify-center items-center border-[1px] border-third rounded-md p-1'>
                            <AiFillHeart
                                size={24}
                                color={tailwindConfig.theme.extend.colors.third}
                            />
                        </div>
                    )} */}
                    <Image
                        // placeholder="blur"
                        // blurDataURL={property.blur}
                        // className='object-fit'
                        className='object-cover'
                        src={project.cover}
                        // width={imageWidth}
                        // height={imageHeight}
                        fill={true}
                        alt={project.slug}
                    />
                        {(project?.videoUrl) ? (
                            <div className='absolute w-full bottom-1 left-1 flex flex-row flex-wrap'>
                                {project.videoUrl ? (
                                    <div className={`rounded-md p-1 text-light bg-red-800 text-sm mr-1 flex flex-row items-center`}><IoMdVideocam /><span className='ml-1'>{dictionary.video}</span></div>
                                ) : null}
                            </div>
                        ) : null}
                        {/* {(project?.promotionTitle?.[lang]) ? (
                            <div className='absolute w-full top-1 left-1 flex flex-row flex-wrap'>
                                <div className={`rounded-md p-1 bg-third text-light border-third border-[1px] text-sm mr-1 flex flex-row items-center`}><span className='ml-1'>{project.promotionTitle}</span></div>
                            </div>
                        ) : null} */}
                </div>

                <div className='p-2'>
                    {/* <div className='text-maincolor text-xl font-semibold rounded-r-md z-10'><h3>{property.category} for {property.saleType !== 'salerent' ? property.saleType : 'Sale and Rent'}</h3></div> */}
                    <div className='text-maincolor text-xl font-semibold rounded-r-md z-10 h-[60px]'><h3 className='line-clamp-2'>{project.name}</h3></div>
                    {project.startingPrice && (
                        <p className='text-secondary text-xl'>{dictionary.fromPriceFront}{project.startingPrice}{dictionary.fromPriceAfter}</p>
                    )}
                    <div className='w-full border-b-[1px] border-forth pb-1 mb-1'>
                        <p className='text-secondary text-xl'>{project.districts[0]?.subdistrictName?.[lang] ? project?.districts[0]?.subdistrictName?.[lang] : project.districts?.[0]?.subdistrict}</p>
                    </div>
                    {/* <div className='flex flex-row w-full justify-between'>
                        <div className='flex flex-row items-center w-full'>
                            <BiArea
                                color={tailwindConfig.theme.extend.colors.secondary}
                            />
                            <span className='ml-2 text-xl text-maincolor'>{project.layouts.length} {dictionary.availableLayouts}</span>
                        </div>
                    </div> */}
                </div>
            </LinkComponent >
        </div>
    )
}

export default NewDevelopmentCard
