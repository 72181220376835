export const company =
{
    name: 'PropertySpace',
    phone1: '089 714 1551 (ENG/TH)',
    phone2: '081 969 4950 (ENG/CN)',
    callPhone1: 'tel:+66897141551',
    callPhone2: 'tel:+66819694950',
    email: 'info@propertyspace.co.th',
    address1: '24/56 Moo 5 Nongprue, Pattaya',
    address2: 'Chonburi, Thailand 20150',
    phoneUrl: 'tel:+66897141551',
    lineUrl: 'https://lin.ee/R88fbe9',
    // whatsappUrl: 'https://line.me/th/Pattayapropertyspace',
    youtubeUrl: 'https://www.youtube.com/@PattayaPropertySpace',
    facebookUrl: 'https://www.facebook.com/PropertySpace.co.th',
    instagramUrl: 'https://www.instagram.com/Pattayapropertyspace/',
    tiktokUrl: 'https://www.tiktok.com/@propertyspace.th'
}
